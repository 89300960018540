<template>
  <div class="contents">
    <div class="container-fluid">
      <div class="profile-content mb-50">
        <div class="row">
          <div class="col-lg-12 mb-2 sticky">
            <div
              class="breadcrumb-main p-3"
              style="background-color: #f4f5f7 !important;"
            >
              <h4 class="text-capitalize breadcrumb-title">
                Attribute Approval
                <ul class="atbd-breadcrumb nav">
                  <li class="atbd-breadcrumb__item">
                    <router-link to="/">
                      <span class="la la-home"></span>
                    </router-link>
                    <span class="breadcrumb__seperator">
                      <span class="la la-slash"></span>
                    </span>
                  </li>
                  <li class="atbd-breadcrumb__item">
                    <span class="bread-item" style="color: #9299b8;">Approval</span>
                    <span class="breadcrumb__seperator">
                      <span class="la la-slash"></span>
                    </span>
                  </li>
                  <li class="atbd-breadcrumb__item">
                    <span class="bread-item">Attribute</span>
                  </li>
                </ul>
              </h4>

            </div>
          </div>
          <div class="container-fluid overlay-clipped">
              <LoaderOverlay
                :active="true"
                v-if="!asyncLoader && loadingAttribute"
                :zIndex="99"
                position="absolute"
                style="height: 100%"
              />
              <no-content v-if="noData"/>
            <div class="row">
              
              <div class="col-12" v-if="attributes.length">
                <div
                  class="userDatatable orderDatatable sellerDatatable global-shadow border mb-30 py-30 px-sm-30 px-20 bg-white radius-xl w-100"
                >
                  <div class="table-responsive">
                    <table class="table mb-0 table-borderless border-0">
                      <thead>
                        <tr class="userDatatable-header">
                          <th scope="col">
                            <span class="userDatatable-title">No</span>
                          </th>
                          <th scope="col">
                            <span class="userDatatable-title">Name</span>
                          </th>
                          <th scope="col">
                            <span class="userDatatable-title">Username</span>
                          </th>
                          <th scope="col">
                            <span class="userDatatable-title">Type</span>
                          </th>
                          <th scope="col">
                            <span class="userDatatable-title">Status</span>
                          </th>
                          <th scope="col" class="text-right">
                            <span class="userDatatable-title">Action</span>
                          </th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr v-for="(attribute, k) in attributes" :key="k">
                          <td>
                            <div class="orderDatatable-title">
                              {{paginationData.offset+k+1}}
                            </div>
                          </td>
                          <td>
                            <div class="orderDatatable-title">
                              <router-link :to="{name: 'officers-profile', params: {officer:{ulid: attribute.ulid}}}">
                                  {{users[attribute.ulid].name}}
                                </router-link>
                            </div>
                          </td>
                          <td>
                            <div class="orderDatatable-title">
                              <router-link :to="{name: 'officers-profile', params: {officer:{ulid: attribute.ulid}}}">
                                  @{{users[attribute.ulid].username}}
                                </router-link>
                            </div>
                          </td>
                          
                          <td>
                            {{roleAttributesMap[attribute.type]}}
                          </td>
                          <td>
                            <div
                              v-if="attribute.status == 1"
                              class="orderDatatable-title"
                            >
                              <span
                                class="atbd-tag tag-success tag-transparented"
                                >Approved</span
                              >
                            </div>
                            <div
                              v-if="attribute.status == 0"
                              class="orderDatatable-title"
                            >
                              <span
                                class="atbd-tag tag-warning tag-transparented"
                                >Pending</span
                              >
                            </div>
                            <div
                              v-if="attribute.status == -1"
                              class="orderDatatable-title"
                            >
                              <span
                                class="atbd-tag tag-danger tag-transparented"
                                >Declined</span
                              >
                            </div>
                          </td>
                            <td class="text-right">
                              <div class="slider-controller float-right w-30" :class="{'el-fade disabled': attribute.status == 1}" :data-toggle="attribute.status == 1 ? '' : 'modal'" data-target="#modal-approve-reject" :id="`slider-${attribute.atlid}`" >
                              <div class="custom-control custom-switch switch-primary switch-md">
                              <input type="checkbox" class="custom-control-input" :id="attribute.atlid" :checked="attribute.status == 1? true : false">
                              <label class="custom-control-label" :for="attribute.atlid" @click.prevent="selectAtlid(attribute, k)"></label>
                              </div>
                              </div>
                            </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="modal-info-delete modal fade show" id="modal-approve-reject" tabindex="-1" role="dialog" aria-hidden="true">
                    <div class="modal-dialog modal-sm modal-info" role="document">
                        <div class="modal-content">
                            <div class="modal-body">
                                <div class="modal-info-body d-flex">
                                    <div class="modal-info-icon warning">
                                        <span data-feather="info"></span>
                                    </div>
                                    <div class="modal-info-text">
                                        <h6>Are you sure you want to approve</h6>
                                        <!-- <p>Some contents...</p> -->
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-danger btn-outlined btn-sm" data-dismiss="modal" @click="approveAttribute(-1)">Reject</button>
                                <button type="button" class="btn btn-success btn-outlined btn-sm" data-dismiss="modal" @click="approveAttribute(1)">Approve</button>
                            </div>
                        </div>
                    </div>
                </div>
                  <!-- p -->
                </div>
              </div>
              <!-- ends: col-12 -->
            </div>
          </div>
          <Pagination :url="attributeUrl" :size="size" @pageChange="pageChange" @loading="loading"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { authComputed } from "@/vuex/helpers.js";
import Pagination from '@/components/Pagination.vue';
import LoaderOverlay from '@/components/LoaderOverlay.vue';
import NoContent from '@/components/NoContent.vue';
import { apiClient } from '@/plugins/restclient';
import { roleAttributesMap } from "@/utilities/constant";
import { mapState } from 'vuex';

export default {
  components: {
    Pagination,
    LoaderOverlay,
    NoContent,
  },
  data() {
    return {
      isLoading: false,
      totalPage: 0,
      attributes: [],
      users:{},
      attributeUrl:"/v1/office/adjudicate/account/attribute",
      size: 10,
      noData: false,
      selectedAttribute: {},
      roleAttributesMap,
      loadingAttribute: false,
      paginationData:''
    };
  },
  metaInfo: {
    title: "Greater Favour",
    titleTemplate: "%s | Attribute Verification",
    htmlAttrs: {
      lang: "en",
      amp: true,
    },
  },
  computed: {
    ...mapState(['asyncLoader']),
    ...authComputed,
  },
  beforeMount() {
    if (this.loggedIn == false) {
      this.$router.push({ path: "/login" });
    }
  },
  methods: {
    loading(status){
        this.loadingAttribute = status
      },
    pageChange(dataSet, paginationData, response){
      this.attributes =  dataSet
      this.users = !response.error && response.info.users;
      this.paginationData = paginationData
      if(this.attributes.length == 0){
          this.noData = true
        }else{
          this.noData = false
        }
    },
    selectAtlid(attribute, k){
      this.selectedAttribute = attribute
      this.selectedAttribute.index = k
    },
    approveAttribute(status){
        this.loadingAttribute = true
        apiClient.patch('/v1/office/account/attribute/approve',{
          atlid:this.selectedAttribute.atlid,
          status
        })
        .then(()=>{
          this.loadingAttribute = false
          if(status == 1){
            document.getElementById(`${this.selectedAttribute.atlid}`).checked = true
            document.getElementById(`slider-${this.selectedAttribute.atlid}`).classList.add('el-fade')
            document.getElementById(`slider-${this.selectedAttribute.atlid}`).classList.add('disabled')
          }
          
          this.selectedAttribute.status = status
          // console.log(this.selectedAttribute, this.attributes[this.selectedAttribute.index])
          this.$set(this.attributes, this.selectedAttribute.index, this.selectedAttribute)
          
        })
        .catch(err =>{
          
          this.loadingAttribute = false
          this.$store.commit('ctoast',{type: 'danger', message: err.response.data.message, timeout: 7000})
        })
      }
  },
};
</script>
